@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@layer components {
  ::-webkit-scrollbar {
    display: none;
  }
  .rowsbg:nth-child(2n +1){
    background: #f1f1f1;
  }
}
.imgContainer {
  overflow: hidden;
}
.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
}
.imgContainer:hover img {
  transform: scale(1.2);
}
